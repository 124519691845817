:root {
  --body-bg: #c1bdba;
  --form-bg: #13232f;
  --white: #ffffff;
  
  --main: #1ab188;
  --main-light: lighten($main,5%);
  --main-dark: darken($main,5%);
  
  --gray-light: #a0b3b0;
  --gray: #ddd;
  
  --thin: 300;
  --normal: 400;
  --bold: 600;
  --br: 4px;
}


body {
  background:var(body-bg);
  font-family: 'Titillium Web', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;

}

form {
  background:rgba(var(--form-bg),.9);
  padding: 40px;
  max-width:500px;
  margin:40px auto;
  border-radius:var(--br);
  box-shadow:0 4px 10px 4px rgba(var(--form-bg),.3);
  border: 1px solid black;
  display: flex;
  flex-direction: column;
}


label {
  align-items: left;
  color:rgba(var(--white),.5);
  font-size:1rem;
  
}

input {
  font-size:22px;
  display:block;
  width:100%;
  height:100%;
  padding:5px 10px;
  margin: .5rem;
  background:none;
  background-image:none;
  border:1px solid var(--gray);
  color: black;
  border-radius:15px;
  transition:border-color .25s ease, box-shadow .25s ease;
  
}


button {
  border:0;
  outline:none;
  border-radius:15px;
  padding:15px;
  font-size:1rem;
  font-weight:var(--bold);
  text-transform:uppercase;
  letter-spacing:.1em;
  background:var(--main);
  color:var(--white);
  transition:all.5s ease;
  
 }

 button:hover, :focus {
  /* background:var(--main-dark); */
  cursor: pointer;
  color: black;
}
.detailsTop{
  border: 1px solid black;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* min-width: 50vh; */
  color: var(--main);
}
.detailsTop > div{
  margin: .5rem;
  display: flex;
  align-items: center;
  align-items: left;
  background-color: var(--gray-light);
  color: var(--form-bg);
  width: 100%;

}
.detailsTop > div>*{
  margin: 1rem;
}

.detailsWrapper{
  display: flex;
  flex-direction: column;
  /* background-color: red; */
  width: 100%;
  height: auto;
  align-items: center;
}

.detailWrapper{
  margin: 1rem;
  /* background-color: #13232f; */
  color: white;
  width: 150%;

}

@media screen and (max-width: 530px){
  .detailWrapper{
    width: 100%;
  }
}

.Dialog{
  background-color: red;
  position: absolute;
}

.details{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.detail{
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background-color: #1ab188; */
  color: #13232f;
  margin: .5rem;
  border: 1px solid gray;
  border-radius: 15px;
  padding: 1rem;
}
.detail > h5{
  height: 1rem;
}